@import-normalize;

:root {
  /* Whitelabel SVG Shades and Tints */
  --primary-color: #4eaea5;
  --primary-S10: #469d95;
  --primary-S20: #3e8b84;
  --primary-S30: #377a73;
  --primary-S40: #2f6863;
  --primary-S50: #275753;
  --primary-S60: #1f4642;
  --primary-S70: #173432;
  --primary-S80: #102321;
  --primary-S90: #081110;
  --primary-T10: #60b6ae;
  --primary-T20: #71beb7;
  --primary-T30: #83c6c0;
  --primary-T40: #95cec9;
  --primary-T50: #a7d7d2;
  --primary-T60: #b8dfdb;
  --primary-T70: #cae7e4;
  --primary-T80: #dcefed;
  --primary-T90: #edf7f6;

  --secondary-color: #4eaea5;
  --secondary-S10: #469d95;
  --secondary-S20: #3e8b84;
  --secondary-S30: #377a73;
  --secondary-S40: #2f6863;
  --secondary-S50: #275753;
  --secondary-S60: #1f4642;
  --secondary-S70: #173432;
  --secondary-S80: #102321;
  --secondary-S90: #081110;
  --secondary-T10: #60b6ae;
  --secondary-T20: #71beb7;
  --secondary-T30: #83c6c0;
  --secondary-T40: #95cec9;
  --secondary-T50: #a7d7d2;
  --secondary-T60: #b8dfdb;
  --secondary-T70: #cae7e4;
  --secondary-T80: #dcefed;
  --secondary-T90: #edf7f6;
  /* Whitelabel SVG Shades and Tints */
  --alertBox: #f4d100;
  --alertBox15percent: rgba(244, 209, 0, 0.15);
  --primary: #4f9e96;
  --primaryRGB: rgb(79, 158, 150);
  --primaryRGB5percent: rgba(79, 158, 150, 0.5);
  --primaryRGB1percent: rgba(79, 158, 150, 0.1);
  --secondary: #f2c94c;
  --black: #1f1f1f;
  --cmyk: #d8d8d8;
  --red: #d50911;
  --error: #ed5e5e;
  --textLink: #1a0dab;
  --textRed: rgba(226, 87, 76, 1);
  --orangeColor: #fcebdb;
  --lightBlue: #e4f3f2;
  --lightRed: #fbe6e4;
  --yellowNew: rgba(255, 234, 138, 0.3);
  --yellowHex: #fff8da;
  --white: 255, 255, 255;
  --hexWhite: #ffffff;
  --skyBlue: #caedea;
  --backgroundColor: #f9fafb;
  --backgroundColorSecondary: rgb(249, 250, 251);
  --backgroundTransparent40percent: rgba(0, 0, 0, 0.4);
  --backgroundTransparent5percent: rgba(0, 0, 0, 0.05);
  --accent: #4eaea5;
  --accent2: rgba(78, 174, 164, 0.2);
  --lightAccent: #def0ef;
  --azure: #ecf3f3;
  --green: #38da33;
  --paleSkyRBG: rgba(106, 114, 112, 0.5);
  --grapeRGB: rgba(65, 64, 64, 0.2);
  --bg1: #fff6ef;
  --solidBg1: #fba364;
  --bg2: #f2f5fe;
  --bg3: #fefae5;
  --bg5: #ececec;
  --bg4: rgba(244, 209, 0, 0.1);
  --bg6: #f0fbfa;
  --bg7: rgba(122, 157, 246, 0.1);
  --yunoActive: rgba(244, 209, 0, 0.2);
  --label2: #7a9df6;
  --inputGrey: #dfe3e8;
  --inputGrey50percent: rgba(223, 227, 232, 0.5);
  --warningBox: rgba(244, 209, 0, 0.15);
  --warningColor: #f2c94c;
  --listBackground: #f9fafb;
  --errorRed: #e2574c;
  --backgroundError: rgba(226, 87, 76, 0.1);
  --activeInput: #4eaea5;
  --labelColor: #637381;
  --leftImageColor: #ebf4f4;
  --placeholderColor: #919eab;
  --accountLabel: #939eaa;
  --pureWhite: #ffffff;
  --warningDocumentColor: #dbb131;
  --workInProgressDocumentColor: #f49342;
  --borderColor: #ebeeef;
  --nonComplaintColor: #e4574a;
  --nonCompliantStatus: rgb(226, 87, 76);
  --nonCompliantStatusBg: rgba(226, 87, 76, 0.15);
  --wipStatus: rgb(244, 147, 66);
  --wipStatusBg: rgba(244, 147, 66, 0.193);
  --compliantStatus: rgb(43, 97, 92);
  --compliantStatusBg: rgba(78, 174, 164, 0.172);
  --actionRequiredStatus: rgb(83, 106, 165);
  --actionRequiredStatusBg: rgba(122, 157, 246, 0.172);
  --subTitleColor: #313332;
  --darkGreyToolTip: #212b36;
  --grey1: #6a7270;
  --grayPrimary: #dddddd;
  --grey: #414040;
  --lightGrey: #ededed;
  --midGrey: #c4c4c4;
  --darkGrey: #767676;
  --grey2: #a7a7a7;
  --grey4: #e9ecec;
  --grey5: #9e9e9e;
  --hamptonsPrimary: #4f9e96;
  --hamptonsPrimaryAlpha: rgba(78, 174, 165, 0.05);
  --hamptonsNewPrimary: #4eaea5;
  --hamptonsSecondary: #f2c94c;
  --hamptonsFocus: #86bbd8;
  --hamptonsGrey: #c4c4c4;
  --hamptonsNewDisabled: #c4cdd5;
  --tablet: "@media (max-width:780px)";
  --mobile: "@media (max-width: 480px)";
  --fontFamily: "Basier Circle";
}

@media (max-width: 768px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 87.5%;
  }
}

* {
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  outline: none;
  margin: 0;
}
input {
  outline: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  outline: none;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: var(--fontFamily);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

label {
  cursor: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

body > #root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  cursor: pointer;
}

/* RESET TABLE STYLES */
/* RESET STYLES */
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* END RESET TABLE STYLES */
.multi-select {
  margin-right: 10px;
}

.multi-select .dropdown {
  width: 300px;
  z-index: 3;
}

.multi-select .dropdown .dropdown-heading {
  border: 2px solid #b8c2cb !important;
  padding: 4px 0 4px 8px;
  margin-right: 8px;
  border-radius: 4px;
}

.leaflet-top,
.leaflet-bottom {
  z-index: 500;
}
