.container {
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.header {
  padding: 2rem 3rem;
  background: var(--primary);
  gap: 2rem;
}

.title {
  color: white;
}

.close {
  cursor: pointer;
}
.close > svg {
  filter: invert(100%) sepia(0%) saturate(0%) brightness(500%) contrast(103%);
}
