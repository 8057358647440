.container {
  margin-bottom: 1rem;
  border-radius: 6px;
  height: fit-content;

  display: flex;
  flex-direction: column;
  text-align: left;
}

.titleFilters {
  margin-bottom: 0.75rem;
  display: flex;
  justify-content: space-between;
  color: var(--labelColor);
}
.clearFiltersContainer {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
.clearFilters {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--labelColor);
  border: solid 2px #dfe3e8;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.clearFilters:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.clearFilters > svg {
  width: 0.8rem;
  margin-left: 0.5rem;
}
.clearFilters > svg > path {
  stroke: var(--labelColor);
}
.downloadButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--labelColor);
  border: solid 2px #dfe3e8;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-right: 0.4rem;
}
.downloadButton > svg {
  margin-left: 0.5rem;
  filter: grayscale(1);
}
.downloadButton:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
}
.filters {
  background-color: var(--pureWhite);
  padding: 0.5rem 1rem 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 0.3rem;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
}
.filters > label > select {
  width: 10rem;
}
.filter {
  max-width: 10rem;
}
.filtersMobile {
  background-color: var(--pureWhite);
  padding: 0.5rem 1rem 0.5rem 1rem;
  display: grid;
  column-gap: 1rem;
  align-items: center;
  grid-template-columns: repeat(1, 1fr);
}
.titleFiltersMobile {
  background-color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  color: var(--labelColor);
}
.clearFiltersMobile {
  margin-bottom: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--labelColor);
}
.clearFiltersMobile > svg {
  width: 0.8rem;
  margin-left: 0.5rem;
}
.clearFiltersMobile > svg > path {
  stroke: var(--labelColor);
}
.icon {
  cursor: pointer;
  height: 100%;
  width: auto;
}

.icon path {
  stroke: var(--primary);
}
