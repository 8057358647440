.divider {
  border-top: 1px solid #c4cdd5;
  width: auto;
  margin: 3rem 223px;
}
.tenanciesContainer {
  border-top: 1px solid #c4cdd5;
  margin: 0 223px;
  padding-top: 1.5rem;
}

.bottomSection {
  margin-bottom: 2rem;
}

.tenancyContainer {
  display: flex;
  margin-top: 1.5rem;
  flex-wrap: wrap;
}
.tenancyItem {
  width: 33%;
  margin-bottom: 1.5rem;
}
.tenancyDivider {
  border-top: 1px solid #eee;
}
